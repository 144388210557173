import { render, staticRenderFns } from "./Abstract4.vue?vue&type=template&id=2f7bbf54&"
var script = {}
import style0 from "./Abstract4.vue?vue&type=style&index=0&id=2f7bbf54&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports